<template>
    <div class="eCommerce-container">
        <div class="tab-top" v-if="$route.query.method === 'stuTrain' || $route.query.method === 'teaTrain'">
            <el-breadcrumb separator=">" class="genera-breadcrumb" style="padding: 16px 0 0px">
                <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                <el-breadcrumb-item>答题详情</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="back-btn" @click="goBack" type="primary">返回</el-button>
        </div>
<!--        电子商务数据采集与处理-->
        <template v-if="moduleId === 44">
            <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
                <el-table-column label="考试内容">
                    <template slot-scope="scope">
                        <div v-if="scope.$index === 0">电子商务数据采集与处理</div>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="数据采集内容" align="center"></el-table-column>
                <el-table-column v-if="$route.query.method !== 'stuTrain'" prop="correct_answer" label="得分答案" align="center"></el-table-column>
                <el-table-column prop="answer" label="学生提交答案" align="center"></el-table-column>
                <el-table-column align="center">
                    <template #header>得分（{{infoScore ? infoScore : 0}}分）</template>
                    <template slot-scope="scope">
                        {{scope.row.score ? scope.row.score : 0}}
                    </template>
                </el-table-column>
            </el-table>
        </template>
<!--        电子商务基础数据分析-->
        <template v-if="moduleId === 45">
            <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
                <el-table-column label="考试内容">
                    <template slot-scope="scope">
                        <div v-if="scope.$index === 0">电子商务基础数据分析</div>
                    </template>
                </el-table-column>
                <el-table-column prop="topic" label="数据分析内容" align="center"></el-table-column>
                <el-table-column v-if="$route.query.method !== 'stuTrain'" prop="score_answer" label="得分答案" align="center"></el-table-column>
                <el-table-column label="学生提交答案" align="center">
                    <template slot-scope="scope">
                        {{$route.query.method === 'stuTrain' ? scope.row.answer : scope.row.stu_answer}}
                    </template>
                </el-table-column>
                <el-table-column align="center">
                    <template #header>得分（{{infoScore ? infoScore : 0}}分）</template>
                    <template slot-scope="scope">
                        {{scope.row.score ? scope.row.score : 0}}
                    </template>
                </el-table-column>
            </el-table>
        </template>
<!--        电子商务数据报告制作-->
        <template v-if="moduleId === 46">
            <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
                <el-table-column label="考试内容">
                    <template slot-scope="scope">
                        <div v-if="scope.$index === 0">电子商务数据报告制作</div>
                    </template>
                </el-table-column>
                <el-table-column prop="answer" label="学生提交答案" align="center">
                    <template slot-scope="scope">
                        <a :href="scope.row.file_path" style="color: #2461EF">下载文件</a>
                    </template>
                </el-table-column>
                <el-table-column align="center" v-if="$route.query.method === 'teaExam' || $route.query.method === 'teaExamTrain'">
                    <template #header>得分（{{infoScore ? infoScore : 0}}分）</template>
                    <template slot-scope="scope">
                        {{scope.row.final_score ? scope.row.final_score : 0}}
                    </template>
                </el-table-column>
                
            </el-table>
        </template>
        <!-- 电子商务数据报表设计制作 -->
    <template v-if="moduleId === 50 || moduleId==97 ">
      <div class="dataDesign">
        <div class="title">
        电子商务数据报表设计制作 得分：{{ total_scoe }}分
       </div>
       <div class="title caozuotitle">
        <span class="caozuo" v-html="task_background"></span>
       </div>
       <div class="echarts">
        <div class="sale_echarts">
            <el-card>
                <div class="echarts_header">
                    <span>销售额趋势分析图</span>
                    <div>
                        <span  class="getDetial" @click="getDetial(1)">查看详情</span>
                        <span class="preview" @click="preview(1)">放大预览</span>

                    </div>
                </div>
                <SaleEcharts :saleData="student_SaleData" :istitle="istitle" :lenged="lenged" :echartsHeight="'260px'"></SaleEcharts>
                <div class="no_answer" v-if="!student_SaleData.length">学生未作答</div>
            </el-card>
        </div>
        <div class="order_echarts">
            <el-card>
                <div class="echarts_header">
                    <span>订单数量与用户对比分析图</span>
                    <div>
                        <span  class="getDetial" @click="getDetial(2)">查看详情</span>
                        <span class="preview" @click="preview(2)">放大预览</span>

                    </div>
                </div>
                <OrderEcharts :orderData="student_OrderData" :istitle="istitle" :lenged="lenged" :echartsHeight="'260px'"></OrderEcharts>
                <div class="no_answer" v-if="!student_OrderData.length">学生未作答</div>
            </el-card>
        </div>
       </div>
       <div class="number_echarts">
        <el-card>
            <div class="echarts_header">
                    <span>销售额与订单数量对比分析图</span>
                    <div class="btn">
                        <span  class="getDetial" @click="getDetial(3)">查看详情</span>
                        <span class="preview" @click="preview(3)">放大预览</span>

                    </div>
                </div>
                <div class="no_answer" v-if="!student_NumberData.length">学生未作答</div>
                <NumberEcharts :numberData="student_NumberData" :istitle="istitle" :lenged="lenged" :echartsHeight="'260px'"></NumberEcharts>
        </el-card>
    </div>
    <!-- 查看详情的对话框 -->
    <el-dialog
  title="查看详情"
  :visible.sync="detailDialogVisible"
  width="60%"
  :before-close="handleCloseDetail">
  <el-table v-show="type==1"
        :data="student_SaleData"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="426"
        :header-cell-style="{
          fontWeight: 'normal',
          height: '40px',
          color: '#666666',
          background: '#F6F6F6',
          fontSize: '16px',
        }"
        :cell-style="{ width: '120px', height: '40px', }"
      >
        <el-table-column align="center" prop="date" label="订单日期"> </el-table-column>
        <el-table-column align="center" prop="sell_amout" label="销售额(元)">
        </el-table-column>
        <!-- <el-table-column align="center" prop="order_num" label="订单数量"> </el-table-column>
        <el-table-column align="center" prop="user_inc_num" label="用户增长">
        </el-table-column> -->
      </el-table>
      <el-table v-show="type==2"
        :data="student_OrderData"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="426"
        :header-cell-style="{
          fontWeight: 'normal',
          height: '40px',
          color: '#666666',
          background: '#F6F6F6',
          fontSize: '16px',
        }"
        :cell-style="{ width: '120px', height: '40px', }"
      >
        <el-table-column align="center" prop="date" label="订单日期"> </el-table-column>
       
        <el-table-column align="center" prop="order_num" label="订单数量"> </el-table-column>
        <el-table-column align="center" prop="user_inc_num" label="用户增长">
        </el-table-column>
      </el-table>
      <el-table v-show="type==3"
        :data="student_NumberData"
        tooltip-effect="dark"
        style="width: 100%"
        max-height="426"
        :header-cell-style="{
          fontWeight: 'normal',
          height: '40px',
          color: '#666666',
          background: '#F6F6F6',
          fontSize: '16px',
        }"
        :cell-style="{ width: '120px', height: '40px', }"
      >
        <el-table-column align="center" prop="date" label="订单日期"> </el-table-column>
        <el-table-column align="center" prop="sell_amout" label="销售额(元)">
        </el-table-column>
        <el-table-column align="center" prop="order_num" label="订单数量"> </el-table-column>
    
      </el-table>
  <span slot="footer" class="dialog-footer">
    <el-button @click="detailDialogVisible = false">关闭</el-button>
    
  </span>
</el-dialog>
<!-- 放大预览的对话框 -->
<el-dialog
  title="放大预览"
  :visible.sync="echartsDialogVisible"
  width="80%"
  :before-close="handleCloseEcharts">
 <div class="preview-anser">
    <div>
        <p>学生答案</p>
        <el-card class="anser">
            <SaleEcharts v-if="type==1"  :saleData="student_SaleData" istitle="true" :lenged="lenged" :echartsHeight="'300px'"></SaleEcharts> 
            <OrderEcharts v-else-if="type==2" :orderData="student_OrderData" istitle="true" :lenged="lenged" :echartsHeight="'300px'"></OrderEcharts>
            <NumberEcharts v-else :numberData="student_NumberData" istitle="true" :lenged="lenged" :echartsHeight="'300px'"></NumberEcharts>
            <div class="no_answer" v-if="!student_SaleData.length && type==1">学生未作答</div>
            <div class="no_answer" v-if=" !student_OrderData.length && type==2">学生未作答</div>
            <div class="no_answer" v-if="!student_NumberData.length && type==3 ">学生未作答</div>
        </el-card>
    </div>
    <div class="teacher_anser">
        <p>正确答案</p>
        <el-card class="anser">
            <SaleEcharts v-if="type==1" :saleData="anser_SaleData" istitle="true" :lenged="lenged" :echartsHeight="'300px'"></SaleEcharts> 
            <OrderEcharts v-else-if="type==2" :orderData="anser_OrderData" istitle="true" :lenged="lenged" :echartsHeight="'300px'"></OrderEcharts>
            <NumberEcharts v-else :numberData="anser_NumberData" istitle="true" :lenged="lenged" :echartsHeight="'300px'"></NumberEcharts>
            <div class="no_answer" v-if="!anser_SaleData.length && type==1">暂未上传作答</div>
            <div class="no_answer" v-if="!anser_OrderData.length && type==2">暂未上传作答</div>
            <div class="no_answer" v-if="!anser_NumberData.length && type==3">暂未上传作答</div>
        </el-card>
    </div>

 </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="echartsDialogVisible = false">关闭</el-button>
   
  </span>
</el-dialog>
      </div>
    </template>
    </div>
</template>

<script>
import SaleEcharts from "@/components/student/exam/ECommerceDataAnalysisAndApplication/saleEcharts.vue"
import OrderEcharts from "@/components/student/exam/ECommerceDataAnalysisAndApplication/orderEcharts.vue"
import NumberEcharts from "@/components/student/exam/ECommerceDataAnalysisAndApplication/numberEcharts.vue"
    import { collectionTrainDetail, teacheropmTrainingLogData, collectionTeachGetAnswer, teacheropmExamData,
             baseDataAnalysisTeaDetail, baseDataAnalysisTeaExamDetail, stuOpTrainData ,ecommerceDataReportExamDetail} from '@/utils/apis.js'
    export default {
        data() {
            return {
                moduleId: Number(this.$route.query.moduleId) || 97,
                infoList: [],
                infoScore: null,
                student_SaleData:[],
                anser_SaleData:[],
                student_OrderData:[],
                anser_OrderData:[],
                student_NumberData:[],
                anser_NumberData:[],
                task_background:'',
                total_scoe:0,
                istitle:false,
                lenged:true,
                detailDialogVisible:false,
                echartsDialogVisible:false,
                type:1

            }
        },
        mounted() {
            this.getInfo()
            // console.log(this.moduleId,"moduid");
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            },
            async getInfo() {
                let params = {}
                let res
                if (this.moduleId === 44) { // 电子商务数据采集与处理
                    if (this.$route.query.method === 'stuTrain' || this.$route.query.method === 'teaTrain') { // 学生训练、教师训练
                        params.train_id = this.$route.query.trainId
                        try {
                            res = await collectionTrainDetail(params)
                        } catch (e) {}
                    } else if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') { // 教师——考试中心——考试数据、实训数据
                        params.user_id = this.$route.query.id // 学生id
                        params.eaxm_id = this.$route.query.examId
                        try {
                            res = await collectionTeachGetAnswer(params)
                        } catch (e) {}
                    }
                    this.$nextTick(() => {
                        this.infoList = res.data.list
                        this.infoScore = res.data.totalScore
                    })
                }
                if (this.moduleId === 45) { // 电子商务基础数据分析
                    if (this.$route.query.method === 'stuTrain') { // 学生训练
                        params.train_id = this.$route.query.trainId
                        try {
                            res = await stuOpTrainData(params)
                        } catch (e) {}
                    } else if (this.$route.query.method === 'teaTrain') {// 教师训练
                        params.train_id = this.$route.query.trainId
                        try {
                            res = await baseDataAnalysisTeaDetail(params)
                        } catch (e) {}
                    } else if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') { // 教师——考试中心——考试数据、实训数据
                        params.user_id = this.$route.query.id // 学生id
                        params.exam_id = this.$route.query.examId
                        try {
                            res = await baseDataAnalysisTeaExamDetail(params)
                        } catch (e) {}
                    }
                    this.$nextTick(() => {
                        if (this.$route.query.method === 'stuTrain') {
                            this.infoList = res.data.stu_answer
                            this.infoScore = res.data.total_score
                        } else {
                            this.infoList = res.data.list
                            this.infoScore = res.data.total_score
                        }
                    })
                }
                if (this.moduleId === 46) { // 电子商务数据报告制作
                    if (this.$route.query.method === 'stuTrain' || this.$route.query.method === 'teaTrain') { // 学生训练、教师训练
                        params.train_id = this.$route.query.trainId
                        try {
                            res = await teacheropmTrainingLogData(params)
                        } catch (e) {}
                    } else if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') { // 教师——考试中心——考试数据、实训数据
                        params.student_id = this.$route.query.id // 学生id
                        params.exam_id = this.$route.query.examId
                        params.module_id = this.$route.query.moduleId
                        try {
                            res = await teacheropmExamData(params)
                        } catch (e) {}
                    }
                    this.$nextTick(() => {
                        this.infoList = res.data
                        if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') {
                            this.infoScore = res.data[0].full_score
                        }
                    })
                }
                if(this.moduleId === 50 || this.moduleId === 97){//电子商务数据报表设计制作
                    
                    if (this.$route.query.method === 'stuTrain' || this.$route.query.method === 'teaTrain') { // 学生训练、教师训练
                       
                    } else if (this.$route.query.method === 'teaExam' || this.$route.query.method === 'teaExamTrain') { // 教师——考试中心——考试数据、实训数据
                        params.user_id = this.$route.query.id // 学生id
                        params.exam_id = this.$route.query.examId//用户id
                        // console.log(params);
                        ecommerceDataReportExamDetail(params).then(res=>{
                            // console.log("考试数据",res);
                            this.student_SaleData=res.data.stu_answer.sale_trend_analysis_data
                            this.student_OrderData=res.data.stu_answer.order_num_comparison_analysis_data
                            this.student_NumberData=res.data.stu_answer.sell_amout_comparison_analysis_data
                            this.anser_SaleData=res.data.detail.sale_trend_analysis_data
                            this.anser_OrderData=res.data.detail.order_num_comparison_analysis_data
                            this.anser_NumberData=res.data.detail.sell_amout_comparison_analysis_data
                            this.task_background=res.data.task_background
                            this.total_scoe=res.data.total_score
                        })
                       
                    }
                }
            },
            getDetial(type){
                this.detailDialogVisible=true
               this.type=type

            },
            preview(type){
                this.type=type
                this.echartsDialogVisible=true
              

            },
            handleCloseDetail(){
               
                this.detailDialogVisible=false

            },
            handleCloseEcharts(){
                this.echartsDialogVisible=false
            }


        },
        components:{
            SaleEcharts,
            OrderEcharts,
            NumberEcharts
        }

    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
       
    }
    .tab-top {
        position: relative;
        .back-btn {
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    .dataDesign{
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .title{
        padding-top: 10px;
        font-size: 16px;
        color: #1D2129 ;
    }
    
    .echarts{
        width: 100%;
        height: 320px;
        display: flex;
        box-sizing: border-box;
        .order_echarts{
            margin-left: 15px;
            flex: 1;
            height: 320px;
        }
        .sale_echarts{
            flex: 1;
            height: 320px;       
        }
    }
    .el-card{
        width: 100%;
        height: 100%;
    }
.number_echarts{
    width: 100%;
    margin-top: 15px;
    height: 320px;
}
.echarts_header{
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    color: #1D2129;
    .btn{
        display: flex;
    }

    .getDetial{
        color: rgb(2,167,240);
        margin-right: 10px;
        font-size: 14px;
        cursor: pointer;
    }
    .preview{
        color: rgb(2,167,240);
        font-size: 14px;
        cursor: pointer;
    }

}
// p{
//     display: inline-block !important;
// }
.caozuo{
       ::v-deep  p{
            display: inline-block !important;
           
        }
}
.preview-anser{
  width: 100%;
  display: flex;
  box-sizing: border-box;
  div{
    flex: 1;

  }
  .teacher_anser{
      margin-left: 20px;
    }
    ::v-deep .el-card__body {
        padding-bottom: 0;
    }
}
.el-card{
    border: none;
}
.no_answer{
    width: 100%;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;

}
.caozuotitle{
    background-color:#FAFAFA ;
    padding: 20px;
    box-sizing: border-box;
    margin: 10px 0;
    color: #333333; 
}
::v-deep .el-dialog__body{
    padding-top: 0px;
    padding-bottom: 60px !important;
}
</style>